const OnboardingScreens = {
  ONBOARDING: {
    PATH: '/activation',
    TITLE: 'Activation',
    TITLE_ALT: 'Activation',
  },
  ONBOARDING_AGENT: {
    PATH: '/agent_activation',
    TITLE: 'Activation Agent',
    TITLE_ALT: 'Activation Agent',
  },
  ONBOARDING_SUCCESS: {
    PATH: '/activation/success',
    TITLE: 'Activation Success',
    TITLE_ALT: 'Activation Success',
  },
  ONBOARDING_SUCCESS_AGENT: {
    PATH: '/activation/success/agent',
    TITLE: 'Activation Success',
    TITLE_ALT: 'Activation Success',
  },
}

export default OnboardingScreens
