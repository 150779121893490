import {EPermissions, ScreenProp} from 'src/app/models'

type TKeys =
  | 'USER_AGEN'
  | 'USER_AGEN_LIST'
  | 'USER_AGEN_ADD'
  | 'USER_AGEN_DETAIL'
  | 'USER_AGEN_EDIT'
  | 'USER_AGEN_UPLOAD'

export const AgenScreen: Record<TKeys, ScreenProp> = {
  USER_AGEN: {
    PATH: '/user/agen',
    TITLE: 'Agen',
    TITLE_ALT: 'Agen',
  },
  USER_AGEN_LIST: {
    PATH: '/user/agen/list',
    TITLE: 'Agen',
    TITLE_ALT: 'Agen',
    PERMISSION: EPermissions.AGEN_VIEW,
  },
  USER_AGEN_ADD: {
    PATH: '/user/agen/add',
    TITLE: 'Buat Agen',
    TITLE_ALT: 'Buat Agen',
    PERMISSION: EPermissions.AGEN_FORM,
  },
  USER_AGEN_DETAIL: {
    PATH: '/user/agen/detail/:id',
    TITLE: 'Detail Agen',
    TITLE_ALT: 'Detail Agen',
    PERMISSION: EPermissions.AGEN_DETAIL,
  },
  USER_AGEN_EDIT: {
    PATH: '/user/agen/edit/:id',
    TITLE: 'Edit Agen',
    TITLE_ALT: 'Edit Agen',
    PERMISSION: EPermissions.AGEN_FORM,
  },
  USER_AGEN_UPLOAD: {
    PATH: '/user/agen/upload',
    TITLE: 'Upload Agen',
    TITLE_ALT: 'Upload Agen',
    PERMISSION: EPermissions.AGEN_IMPORT,
  },
}
