import {FC} from 'react'
import {useMasterLayout} from 'src/app/context/MasterContext'
import {cn} from 'src/app/utils/cn.utils'
import AsideGradient from './AsideGradient'
import AsideLogo from './AsideLogo'
import AsideMenu from './AsideMenu'
import AsideToggler from './AsideToggler'

export const AsideWrapper: FC = () => {
  const {minimize} = useMasterLayout()

  return (
    <div
      className={cn(
        'bg-gradient-to-br from-primary-700 to-primary-600',
        'fixed top-0 left-0 bottom-0 z-30 transition-all duration-300 group flex flex-col -translate-x-full',
        'lg:translate-x-0',
        {'lg:w-[72px]': minimize},
        {'translate-x-0 w-[264px]': !minimize}
      )}
    >
      <div className='relative z-10 flex items-center'>
        <AsideLogo />
        <AsideToggler />
      </div>

      <div className='z-10 flex flex-col flex-1 w-auto h-full p-0 m-0'>
        <div className='pb-20 mt-5 mb-20 overflow-y-scroll no-scrollbar'>
          <AsideMenu />
        </div>
      </div>

      {/* STYLING PURPOSE */}
      <AsideGradient />
    </div>
  )
}
