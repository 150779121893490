import {useFormik} from 'formik'
import {FC, useMemo} from 'react'
import {useDispatch} from 'react-redux'
import {FormSelect, GButton} from 'src/app/components/Libs'
import {IOnboarding} from 'src/app/models'
import OnboardingRedux from 'src/app/store/Onboarding/OnboardingRedux'
import {toAbsoluteUrl} from 'src/app/utils/asset-helpers.utils'

const LanguageSection: FC<IOnboarding> = ({state}) => {
  const dispatch = useDispatch()

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      language: state.language as OnboardingRedux.TLanguageState,
    },
    onSubmit: ({language}) => {
      dispatch(OnboardingRedux.actions.setLanguage(language))
      dispatch(OnboardingRedux.actions.setStep(2))
    },
  })

  const languageOpt = useMemo(() => [{label: 'Bahasa Indonesia', value: 'id'}], [])

  return (
    <div className='flex flex-col items-center justify-center w-full h-full bg-[#F4F9FB]'>
      <img src={toAbsoluteUrl('/logo_optera.svg')} alt='optera_logo' className='h-28 xl:h-32' />
      <div className='mt-16 font-medium text-center text-neutral-900 text-fs-3'>
        Selamat Datang <br /> di OpteraOne
      </div>
      <div className='mt-8 text-center text-neutral-600 text-fs-7'>
        Pilih bahasa & lanjutkan proses pembuatan akun
      </div>
      <form
        noValidate
        onSubmit={formik.handleSubmit}
        className='flex items-center justify-center gap-4 mt-6 text-center'
      >
        <div className='flex items-center w-full'>
          <FormSelect
            className='w-full min-w-[218px] text-left'
            placeholder=''
            options={languageOpt}
            selectedValue={formik.values.language}
            value={formik.values.language}
            changedValue={(payload) => formik.setFieldValue('language', payload.value)}
          />
        </div>
        <div>
          <GButton
            type='submit'
            endIcon='IconArrowRight'
            className='bg-[#0085E5] border-[#0085E5] h-[44px]'
          >
            Lanjut
          </GButton>
        </div>
      </form>
    </div>
  )
}

export default LanguageSection
