import {EPermissions, ScreenProp} from 'src/app/models'

type TKeys =
  | 'JOB_TUGAS'
  | 'JOB_TUGAS_LIST_ACTIVE'
  | 'JOB_TUGAS_LIST_DRAFT'
  | 'JOB_TUGAS_ADD'
  | 'JOB_TUGAS_DETAIL'
  | 'JOB_TUGAS_EDIT'
  | 'JOB_TUGAS_UPLOAD'

export const JobTugasScreen: Record<TKeys, ScreenProp> = {
  JOB_TUGAS: {
    PATH: '/job',
    TITLE: 'Job & Tugas',
    TITLE_ALT: 'Job & Tugas',
  },
  JOB_TUGAS_LIST_ACTIVE: {
    PATH: '/job/list/active',
    TITLE: 'Job & Tugas',
    TITLE_ALT: 'Job & Tugas',
    PERMISSION: EPermissions.JOB_VIEW,
  },
  JOB_TUGAS_LIST_DRAFT: {
    PATH: '/job/list/draft',
    TITLE: 'Job & Tugas',
    TITLE_ALT: 'Job & Tugas',
    PERMISSION: EPermissions.JOB_VIEW,
  },
  JOB_TUGAS_ADD: {
    PATH: '/job/add',
    TITLE: 'Buat Job & Tugas',
    TITLE_ALT: 'Buat Job & Tugas',
    PERMISSION: EPermissions.JOB_FORM,
  },
  JOB_TUGAS_DETAIL: {
    PATH: '/job/detail/:id',
    TITLE: 'Detail Job & Tugas',
    TITLE_ALT: 'Detail Job & Tugas',
    PERMISSION: EPermissions.JOB_DETAIL,
  },
  JOB_TUGAS_EDIT: {
    PATH: '/job/edit/:id',
    TITLE: 'Edit Job & Tugas',
    TITLE_ALT: 'Edit Job & Tugas',
    PERMISSION: EPermissions.JOB_FORM,
  },
  JOB_TUGAS_UPLOAD: {
    PATH: '/job/upload',
    TITLE: 'Upload Job & Tugas',
    TITLE_ALT: 'Upload Job & Tugas',
    PERMISSION: EPermissions.JOB_IMPORT,
  },
}
