import axios from 'axios'
import {BaseResponse, Response} from 'src/app/models'
import {TPermissions} from 'src/app/models/User/Permission.model'

export const PERMISSIONS = '/api/v1/admins/users/my-permissions'

//react-query key
export const DETAIL_PERMISSION_KEY = 'detail-permissions'

export const getDetailPermission = () => {
  return axios.get<BaseResponse<Response<TPermissions>>>(PERMISSIONS)
}
