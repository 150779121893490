import {FC, useEffect, useState} from 'react'
import {shallowEqual, useDispatch, useSelector} from 'react-redux'
import {useHistory, useLocation} from 'react-router-dom'
import {ToastContainer} from 'react-toastify'
import {useWebTitle} from 'src/app/hooks/title.hook'
import {logoutUser, verifyOnboardingActivation} from 'src/app/services'
import AuthRedux from 'src/app/store/Auth/AuthRedux'
import OnboardingRedux from 'src/app/store/Onboarding/OnboardingRedux'
import {RootState} from 'src/app/store/RootReducer'
import AuthScreens from '../../auth/AuthScreens'
import AdminSection from '../components/AdminSection/AdminSection'
import AgentSection from '../components/AgentSection/AgentSection'
import ExpiredSection from '../components/ExpiredSection/ExpiredSection'
import IdentitySection from '../components/IdentitySection/IdentitySection'
import LanguageSection from '../components/LanguageSection/LanguageSection'
import SuccessSection from '../components/SuccessSection/SuccessSection'
import WorkSection from '../components/WorkSection/WorkSection'

const OnboardingPage: FC = () => {
  useWebTitle('Activation')
  const history = useHistory()
  let query = new URLSearchParams(useLocation().search)
  const token = query.get('t') as string
  const dispatch = useDispatch()

  const [activeSession, setActiveSession] = useState<boolean>(true)

  const onboardingRedux = useSelector<RootState>(
    ({onboarding}) => onboarding,
    shallowEqual
  ) as OnboardingRedux.IOnboardingState

  const {step} = onboardingRedux

  const handleLogout = async () => {
    await logoutUser()
    dispatch(AuthRedux.actions.logout())
  }

  useEffect(() => {
    handleLogout()
    //eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (token) {
      verifyOnboardingActivation({t: token})
        .then((res) => {
          const data = res?.data?.response_output?.detail

          dispatch(
            OnboardingRedux.actions.setProfile({
              company_name: data?.company_name,
              email: data?.email,
              level: data?.level,
            })
          )

          if (data?.success) {
            setActiveSession(true)
          } else setActiveSession(false)
        })
        .catch(() => {
          setActiveSession(false)
        })
    }
  }, [token, dispatch])

  useEffect(() => {
    if (!token) history.replace(AuthScreens.LOGIN_EMAIL.PATH)
  }, [history, token])

  useEffect(() => {
    const onBeforeUnload = (ev: BeforeUnloadEvent) => {
      ev.preventDefault()
      ev.returnValue = ''

      return ''
    }

    if (activeSession) window.addEventListener('beforeunload', onBeforeUnload)

    return () => {
      window.removeEventListener('beforeunload', onBeforeUnload)
    }
  }, [activeSession])

  if (!token) return null

  return (
    <div className='w-full min-h-screen'>
      {activeSession ? (
        <>
          {step === 1 && <LanguageSection state={onboardingRedux} />}
          {step === 2 && <IdentitySection state={onboardingRedux} token={token} />}
          {step === 3 && <AdminSection state={onboardingRedux} />}
          {step === 4 && <AgentSection state={onboardingRedux} />}
          {step === 5 && <WorkSection state={onboardingRedux} token={token} />}
          {step === 6 && <SuccessSection state={onboardingRedux} />}
        </>
      ) : (
        <ExpiredSection />
      )}

      <ToastContainer />
    </div>
  )
}

export default OnboardingPage
