import {TPermissions} from 'src/app/models'

const DashboardScreens = {
  DASHBOARD: {
    PATH: '/dashboard',
    TITLE: 'Dashboard',
    TITLE_ALT: 'Dashboard',
    PERMISSION: 'dashboard.view' as TPermissions,
  },
}

export default DashboardScreens
