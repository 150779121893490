import {useMutation, useQuery} from '@tanstack/react-query'
import {FormikProps} from 'formik'
import {useDispatch} from 'react-redux'
import {useHistory} from 'react-router-dom'
import {toast} from 'react-toastify'
import CustomToast, {
  CustomToastStyling,
} from 'src/app/components/Widgets/ToastCollection/CustomToast'
import {useErrorQuery} from 'src/app/hooks/error-query.hook'
import {IAdminDetail, IAdminRequest} from 'src/app/models'
import UserScreens from 'src/app/modules/user/routes/Screen'
import FilterRedux from 'src/app/store/Filter/FilterRedux'
import {DETAIL_USER_KEY, getDetailUser, saveUser} from './User.services'

export const useUserDetail = (id: string, onSuccess?: (data: IAdminDetail) => void) => {
  const {errorTemp} = useErrorQuery()

  return useQuery({
    queryKey: [DETAIL_USER_KEY, id],
    queryFn: async () => (await getDetailUser(id)).data.response_output?.detail,
    onSuccess,
    onError: (e) => errorTemp(e),
    enabled: !!id,
  })
}

export const useSubmitUser = (formik: FormikProps<IAdminRequest>, id?: string) => {
  const {errorComplete} = useErrorQuery()
  const history = useHistory()
  const dispatch = useDispatch()

  const onSuccess = () => {
    dispatch(FilterRedux.actions.setRemoveFilter())

    toast(
      <CustomToast
        message={id ? 'Admin berhasil diupdate.' : 'Undangan admin berhasil dikirim.'}
      />,
      CustomToastStyling
    )
    history.push(UserScreens.USER_ADMIN_LIST.PATH)
  }

  const onError = (e: any) => errorComplete(e, formik)

  const handlePayload = (payload: IAdminRequest) => {
    return {
      ...payload,
      employee_number: payload.employee_number !== '' ? payload.employee_number : null,
    }
  }

  const submitFn = useMutation({
    mutationFn: (payload: IAdminRequest) => saveUser(handlePayload(payload), id),
    onSuccess,
    onError,
  })

  return {submitFn, onSuccess, onError}
}
