import axios from 'axios'
import {
  BaseResponse,
  IDashboard,
  IDashboardJobCount,
  IDashboardQuery,
  Response,
} from 'src/app/models'

export const DASHBOARD = '/api/v1/admins/dashboard'

//react-query key
export const LIST_DASHBOARD_KEY = 'list-dashboard'
export const DETAIL_DASHBOARD_KEY = 'detail-dashboard'

export const getDetailDashboard = (params?: IDashboardQuery) => {
  return axios.get<BaseResponse<Response<IDashboard>>>(DASHBOARD, {
    params,
  })
}

export const getDetailJobCountDashboard = (params?: any) => {
  return axios.get<BaseResponse<Response<IDashboardJobCount[]>>>(`${DASHBOARD}/job-done`, {
    params,
  })
}
