import {FC, ReactNode} from 'react'
import GIcon from 'src/app/components/Libs/Icon/GIcon'
import {IOnboarding} from 'src/app/models'
import StartingSteps from '../StartingSteps/StartingSteps'
import TopLayout from './TopLayout'

interface IFormLayout extends IOnboarding {
  children: ReactNode
}

const FormLayout: FC<IFormLayout> = ({state, children}) => {
  return (
    <TopLayout state={state}>
      <div className='relative flex items-start justify-between'>
        <div className='min-w-[270px] sticky top-[128px] mr-14'>
          <div className='flex items-center gap-4 py-8 pr-8'>
            <div>
              <GIcon icon='IconBuilding' />
            </div>
            <div className='font-semibold text-neutral-900 text-fs-7 whitespace-nowrap'>
              {state?.profile?.company_name}
            </div>
          </div>
          <div className='w-full border-t border-neutral-300'></div>

          <StartingSteps state={state} />
        </div>

        {children}
      </div>
    </TopLayout>
  )
}

export default FormLayout
