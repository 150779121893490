import {FC} from 'react'
import {shallowEqual, useSelector} from 'react-redux'
import {Redirect, Route, Switch} from 'react-router-dom'
import MasterLayout from '../components/Layout/MasterLayout'
import {Logout} from '../modules/auth/page/Logout/Logout'
import Error404 from '../modules/error/error404'
import {RootState} from '../store/RootReducer'
import {toAbsoluteUrl} from '../utils/asset-helpers.utils'
import {PrivateRoutes} from './PrivateRoutes'
import {PublicRoutes} from './PublicRoutes'

const Routes: FC = () => {
  const {accessToken, fetchingUser: loadingRedux} = useSelector(
    (state: RootState) => ({
      accessToken: Boolean(state.auth.accessToken),
      fetchingUser: state.auth.fetchingUser,
    }),
    shallowEqual
  )

  return (
    <Switch>
      {/* Public Routes for Unauthenticated Users */}
      {!accessToken ? <PublicRoutes /> : <Redirect from='/auth' to='/' />}

      <Route path='/logout' component={Logout} />

      {/* Check Loading State */}
      {accessToken && loadingRedux === 'loading' ? (
        <Route path='*'>
          <div id='splash-screen' className='splash-screen'>
            <img
              src={toAbsoluteUrl('/logo_optera.svg')}
              alt='Optera_logo'
              style={{height: '5em'}}
            />
            <span>Loading ...</span>
          </div>
        </Route>
      ) : (
        // Private Routes
        <MasterLayout>
          <PrivateRoutes />
          <Route path='/error/404' component={Error404} />
        </MasterLayout>
      )}
    </Switch>
  )
}

export {Routes}
