import {useMutation} from '@tanstack/react-query'
import {FC, useEffect, useState} from 'react'
import PinInput from 'react-pin-input'
import {shallowEqual, useDispatch, useSelector} from 'react-redux'
import {useHistory, useLocation} from 'react-router-dom'
import {GButton} from 'src/app/components/Libs'
import {useErrorQuery} from 'src/app/hooks/error-query.hook'
import {useWebTitle} from 'src/app/hooks/title.hook'
import {IOnboardingAgent, IOnboardingAgentRequest} from 'src/app/models'
import {logoutUser, saveOnboardingAgent, verifyOnboardingAgent} from 'src/app/services'
import AuthRedux from 'src/app/store/Auth/AuthRedux'
import OnboardingRedux from 'src/app/store/Onboarding/OnboardingRedux'
import {RootState} from 'src/app/store/RootReducer'
import AuthScreens from '../../auth/AuthScreens'
import ExpiredSection from '../components/ExpiredSection/ExpiredSection'
import Topbar from '../components/Topbar/Topbar'
import OnboardingScreens from '../routes/Screen'

const OnboardingAgent: FC = () => {
  useWebTitle('Aktivasi Agen')
  const history = useHistory()
  let query = new URLSearchParams(useLocation().search)
  const token = query.get('t') as string
  const dispatch = useDispatch()
  const {errorTemp} = useErrorQuery()

  const [pin, setPin] = useState('')
  const [response, setResponse] = useState<IOnboardingAgent>({})
  const [activeSession, setActiveSession] = useState<boolean>(true)

  const onboardingRedux = useSelector<RootState>(
    ({onboarding}) => onboarding,
    shallowEqual
  ) as OnboardingRedux.IOnboardingState

  const handleLogout = async () => {
    await logoutUser()
    dispatch(AuthRedux.actions.logout())
  }

  useEffect(() => {
    handleLogout()
    //eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (token) {
      verifyOnboardingAgent({t: token})
        .then((res) => {
          const data = res?.data?.response_output?.detail

          setResponse(data as IOnboardingAgent)

          if (data?.success) {
            setActiveSession(true)
          } else setActiveSession(false)
        })
        .catch(() => {
          setActiveSession(false)
        })
    }
  }, [token, dispatch])

  useEffect(() => {
    if (!token) history.replace(AuthScreens.LOGIN_EMAIL.PATH)
  }, [history, token])

  const submitFn = useMutation({
    mutationFn: () => {
      const payload: IOnboardingAgentRequest = {
        company_code: response.company_code,
        mobile_number: response.mobile_number,
        pin: pin,
      }

      return saveOnboardingAgent(payload, token)
    },
    onSuccess: () => {
      history.replace(OnboardingScreens.ONBOARDING_SUCCESS_AGENT.PATH)
    },
    onError: (e) => errorTemp(e),
  })
  if (!token) return null

  if (!activeSession) return <ExpiredSection />

  return (
    <div className='flex items-center justify-center w-full h-screen bg-neutral-25'>
      <Topbar state={onboardingRedux} />
      <div className='text-center px-4 lg:px-6 py-12 w-[420px] h-[420px] lg:bg-white lg:shadow-lg lg:border lg:border-neutral-50 rounded-lg flex flex-col justify-between items-center'>
        <div>
          <div className='font-semibold text-neutral-900 text-fs-6'>Masukkan PIN</div>
          <div className='mt-6 text-neutral-600'>
            Proses onboarding nomor: <span className='font-semibold'>{response.mobile_number}</span>{' '}
            hampir selesai
          </div>
        </div>

        <div>
          <PinInput
            length={6}
            onChange={(value) => setPin(value)}
            type='numeric'
            inputMode='number'
            inputStyle={{
              borderColor: pin.length < 6 ? '#D0D5DD' : '#91DAF1',
              margin: '0 2px',
              fontSize: 36,
            }}
            inputFocusStyle={{borderColor: pin.length < 6 ? 'black' : '#91DAF1'}}
            focus
            // onComplete={(value) => {}}
            autoSelect={true}
            placeholder='0'
          />
        </div>

        <div className='w-full'>
          <GButton
            className='w-full'
            size='large'
            disabled={pin.length < 6 || submitFn.isLoading}
            onClick={() => submitFn.mutate()}
            loading={submitFn.isLoading}
          >
            Submit
          </GButton>
        </div>
      </div>
    </div>
  )
}

export default OnboardingAgent
