import {useFormik} from 'formik'
import {FC, useMemo} from 'react'
import {useDispatch} from 'react-redux'
import {IOnboarding} from 'src/app/models'
import OnboardingRedux from 'src/app/store/Onboarding/OnboardingRedux'
import {toAbsoluteUrl} from 'src/app/utils/asset-helpers.utils'
import {SelectButton} from '../../../../components/Widgets/SelectButton'

const Topbar: FC<IOnboarding> = ({state}) => {
  const dispatch = useDispatch()

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      language: state.language as OnboardingRedux.TLanguageState,
    },
    onSubmit: ({language}) => {
      dispatch(OnboardingRedux.actions.setLanguage(language))
    },
  })

  const languageOpt = useMemo(() => [{label: 'ID', value: 'id'}], [])

  return (
    <div className='fixed z-10 top-0 left-0 right-0 h-[72px] bg-white shadow-sm drop-shadow-sm flex items-center justify-center'>
      <div className='container flex items-center justify-between px-[5%]'>
        <div>
          <img src={toAbsoluteUrl('/logo_optera.svg')} alt='optera_logo' className='h-8' />
        </div>
        <div>
          <SelectButton
            className='w-[74px] h-[40px] text-left bg-white'
            placeholder=''
            options={languageOpt}
            selectedValue={formik.values.language}
            value={formik.values.language}
            changedValue={(payload) => formik.setFieldValue('language', payload.value)}
          />
        </div>
      </div>
    </div>
  )
}

export default Topbar
