import {useMutation} from '@tanstack/react-query'
import {useFormik} from 'formik'
import {ChangeEvent, FC, useCallback} from 'react'
import {useGoogleReCaptcha} from 'react-google-recaptcha-v3'
import {useDispatch} from 'react-redux'
import {Link} from 'react-router-dom'
import {FormLabel, FormPassword, FormText, GAlert, GButton} from 'src/app/components/Libs'
import {useOnline} from 'src/app/hooks/online.hook'
import {useWebTitle} from 'src/app/hooks/title.hook'
import {loginByEmail} from 'src/app/services'
import AuthRedux from 'src/app/store/Auth/AuthRedux'
import {toAbsoluteUrl} from 'src/app/utils/asset-helpers.utils'
import {cn} from 'src/app/utils/cn.utils'
import {emailRegExp} from 'src/app/utils/input.utils'
import * as Yup from 'yup'
import AuthScreens from '../../AuthScreens'

const loginSchema = Yup.object().shape({
  company_id: Yup.string().required('ID Perusahaan wajib diisi'),
  email: Yup.string()
    .email('Format alamat email tidak valid')
    .required('Alamat email wajib diisi')
    .matches(emailRegExp, 'Format alamat email tidak valid')
    .max(100, 'Maksimal karakter 100'),
  password: Yup.string().required('Password wajib diisi').max(256, 'Maksimal karakter 256'),
})

const initialValues = {
  company_id: '',
  email: '',
  password: '',
  captcha: '',
}

const LoginEmail: FC = () => {
  const dispatch = useDispatch()
  useWebTitle('Login')
  const {isOnline, errorOffline} = useOnline()

  const {executeRecaptcha} = useGoogleReCaptcha()

  const handleReCaptchaVerify = useCallback(
    async (payload: typeof initialValues) => {
      if (!executeRecaptcha || process.env.NODE_ENV === 'development') {
        console.log('Execute recaptcha not yet available')
        payload.captcha = process.env.REACT_APP_DEFAULT_CAPTCHA_SITEKEY as string
      } else {
        payload.captcha = await executeRecaptcha()
      }

      return loginByEmail(payload)
    },
    [executeRecaptcha]
  )

  const submitFn = useMutation({
    mutationFn: (payload: typeof initialValues) => handleReCaptchaVerify(payload),
    onSuccess: (result) => {
      const data = result?.data?.response_output?.detail
      const {token, refreshtoken} = data

      dispatch(AuthRedux.actions.login(token, refreshtoken))
    },
    onError: (e: any) => {
      const status = e.response.status

      if (!isOnline) {
        formik.setStatus(errorOffline)
        return
      }

      if (isOnline) {
        if (status >= 500 || status === 404) {
          formik.setStatus('Layanan Sementara Tidak Tersedia. Silakan coba lagi nanti.')
        } else {
          formik.setStatus(
            'Login gagal! Silakan periksa kembali nama pengguna dan kata sandi Anda, lalu coba lagi.'
          )
        }
      }
    },
  })

  const formik = useFormik({
    initialValues,
    validateOnBlur: false,
    validateOnChange: false,
    validationSchema: loginSchema,
    onSubmit: (values) => {
      if (isOnline) {
        submitFn.mutate(values)
      } else {
        formik.setStatus(errorOffline)
      }
    },
  })

  const handleChangeCompanyId = (e: ChangeEvent<HTMLInputElement>) => {
    const capitalizedValue = e.target.value.toUpperCase()
    formik.setFieldValue('company_id', capitalizedValue)
  }

  return (
    <div data-testid='login-email-page' className='w-full'>
      <img src={toAbsoluteUrl('/logo_optera.svg')} alt='optera_logo' className='h-[62px] mb-11' />

      <div className='mb-12'>
        <div className='mb-2 font-semibold text-fs-5 text-neutral-900'>Selamat Datang</div>
        <div className='text-neutral-600'>Silakan masuk menggunakan akun Anda</div>
      </div>

      <form className='w-full' onSubmit={formik.handleSubmit} noValidate>
        <div className='mb-7'>
          <FormLabel className='mb-2'>ID Perusahaan</FormLabel>
          <FormText
            {...formik.getFieldProps('company_id')}
            name='company_id'
            type='text'
            placeholder='Masukkan ID Perusahaan'
            maxLength={10}
            error={formik.errors.company_id}
            touched={formik.touched.company_id}
            onChange={handleChangeCompanyId}
          />
        </div>

        <div className='mb-7'>
          <FormLabel className='mb-2'>Email</FormLabel>
          <FormText
            {...formik.getFieldProps('email')}
            name='email'
            type='email'
            placeholder='Masukkan email'
            maxLength={50}
            error={formik.errors.email}
            touched={formik.touched.email}
          />
        </div>

        <div className={cn(formik.status ? 'mb-5' : 'mb-[52px]')}>
          <FormLabel className='mb-2'>Password</FormLabel>
          <FormPassword
            {...formik.getFieldProps('password')}
            name='password'
            placeholder='Masukkan password'
            minLength={8}
            error={formik.errors.password}
            touched={formik.touched.password}
          />

          <div className='pb-2 mt-4 font-medium text-end text-primary-500'>
            <Link to={AuthScreens.FORGOT.PATH}>
              <span className='cursor-pointer'>Lupa Password</span>
            </Link>
          </div>
        </div>

        {formik.status && (
          <GAlert
            className='mb-[52px] flex items-center'
            icon='IconWarning2'
            iconClassname='w-6 h-6'
          >
            {formik.status}
          </GAlert>
        )}

        <GButton
          type='submit'
          size='large'
          className='w-full'
          loading={submitFn.isLoading}
          disabled={submitFn.isLoading || !formik.dirty}
        >
          Masuk
        </GButton>
      </form>
    </div>
  )
}

export default LoginEmail
