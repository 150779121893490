import {defaultBreadcrumbs, ScreenProp} from 'src/app/models'

export const OrganizationScreen: Record<string, ScreenProp> = {
  ORGANIZATION: {
    PATH: '/administration/setup-organization',
    TITLE: 'Client Management',
    TITLE_ALT: 'Client Management',
    breadcrumbs: defaultBreadcrumbs,
  },
  ORGANIZATION_ADD: {
    PATH: '/administration/setup-organization/add',
    TITLE: 'Tambah Client',
    TITLE_ALT: 'Tambah Client',
    breadcrumbs: defaultBreadcrumbs,
  },
  ORGANIZATION_EDIT: {
    PATH: '/administration/setup-organization/edit/:id',
    TITLE: 'Edit Client ',
    TITLE_ALT: 'Edit Client',
    breadcrumbs: defaultBreadcrumbs,
  },
  ORGANIZATION_DETAIL: {
    PATH: '/administration/setup-organization/detail/:id',
    TITLE: 'Detail Client',
    TITLE_ALT: 'Detail Client',
    breadcrumbs: defaultBreadcrumbs,
  },
}
