import {FC} from 'react'
import {cn} from 'src/app/utils/cn.utils'
import {
  FormCheckbox,
  FormDatepicker,
  FormLabel,
  FormMultiSelect,
  FormPassword,
  FormRadio,
  FormSelect,
  FormText,
  FormTextarea,
  MultiSelectOption,
  SelectOption,
} from '../partials'
import {CreateFormProps, GFormProps} from './FormBuilder.types'
import {getFormLabelStyle, getFormListStyle} from './FormBuilder.utils'

const commonProps = (props: CreateFormProps) => {
  const {arrayValue, arrayClassName, data, formik} = props
  const {className, value} = data
  return {
    className: arrayValue ? cn(arrayClassName, className) : cn(className),
    error: formik.errors[`${value}`] as string,
    touched: formik.touched[`${value}`] as boolean,
  }
}

const FormTextBuild: FC<CreateFormProps> = (props) => {
  if (props.data.type === 'text' || props.data.type === 'email') {
    const {value, onChange, isShow, ...rest} = props.data

    return (
      <FormText
        {...props.formik.getFieldProps(`${value}`)}
        {...commonProps(props)}
        name={value as string}
        onChange={(e) => (onChange ? onChange(e) : props.formik.handleChange(e))}
        {...rest}
      />
    )
  }
  return null
}

const FormTextareaBuild: FC<CreateFormProps> = (props) => {
  if (props.data.type === 'textarea') {
    const {value, isShow, ...rest} = props.data

    return (
      <FormTextarea
        {...props.formik.getFieldProps(`${value}`)}
        {...commonProps(props)}
        name={value as string}
        {...rest}
      />
    )
  }
  return null
}

const FormPasswordBuild: FC<CreateFormProps> = (props) => {
  if (props.data.type === 'password') {
    const {value, onChange, isShow, type, ...rest} = props.data

    return (
      <FormPassword
        {...props.formik.getFieldProps(`${value}`)}
        {...commonProps(props)}
        name={value as string}
        minLength={8}
        {...rest}
      />
    )
  }
  return null
}

const FormSelectBuild: FC<CreateFormProps> = (props) => {
  if (props.data.type === 'select') {
    const {value, changedValue, isShow, ...rest} = props.data

    return (
      <FormSelect
        {...commonProps(props)}
        changedValue={(payload: SelectOption) => {
          changedValue
            ? changedValue(payload)
            : props.formik.setFieldValue(`${value}`, payload.value)
        }}
        selectedValue={props.formik.values[`${value}`]}
        {...rest}
      />
    )
  }
  return null
}

const FormMultiSelectBuild: FC<CreateFormProps> = (props) => {
  if (props.data.type === 'multi-select') {
    const {value, changedValue, options, isShow, ...rest} = props.data

    return (
      <FormMultiSelect
        {...commonProps(props)}
        changedValue={(payload, actionMeta) => {
          changedValue
            ? changedValue(payload, actionMeta)
            : props.formik.setFieldValue(`${value}`, payload)
        }}
        selectedValue={props.formik.values[`${value}`]}
        options={options as MultiSelectOption[]}
        {...rest}
      />
    )
  }
  return null
}

const FormRadioBuild: FC<CreateFormProps> = (props) => {
  if (props.data.type === 'radio') {
    const {value, isShow, ...rest} = props.data

    return (
      <FormRadio
        {...commonProps(props)}
        checkedValue={props.formik.values[`${value}`]}
        changedValue={(payload: any) => props.formik.setFieldValue(`${value}`, payload)}
        {...rest}
      />
    )
  }
  return null
}

const FormCheckboxBuild: FC<CreateFormProps> = (props) => {
  if (props.data.type === 'checkbox') {
    const {value, checkedValue, activeValue, inactiveValue, isShow, ...rest} = props.data

    return (
      <FormCheckbox
        {...commonProps(props)}
        checkedValue={checkedValue ?? props.formik.values[`${value}`]}
        value={value as string}
        changedValue={(e: any) =>
          props.formik.setFieldValue(`${value}`, e.target.checked ? activeValue : inactiveValue)
        }
        activeValue={props.data.activeValue}
        inactiveValue={props.data.inactiveValue}
        {...rest}
      />
    )
  }
  return null
}

const FormDatepickerBuild: FC<CreateFormProps> = (props) => {
  if (props.data.type === 'date') {
    const {value, isShow, ...rest} = props.data

    return (
      <FormDatepicker
        {...props.formik.getFieldProps(`${value}`)}
        {...commonProps(props)}
        onChange={(payload: any) => {
          props.formik.setFieldValue(`${value}`, payload?.toDate())
        }}
        id={value?.toString()}
        {...rest}
      />
    )
  }
  return null
}

const CreateForm: FC<CreateFormProps> = (props) => {
  switch (props.data.type) {
    case 'text':
    case 'email':
      return <FormTextBuild {...props} />
    case 'textarea':
      return <FormTextareaBuild {...props} />
    case 'password':
      return <FormPasswordBuild {...props} />
    case 'select':
      return <FormSelectBuild {...props} />
    case 'multi-select':
      return <FormMultiSelectBuild {...props} />
    case 'radio':
      return <FormRadioBuild {...props} />
    case 'checkbox':
      return <FormCheckboxBuild {...props} />
    case 'date':
      return <FormDatepickerBuild {...props} />
    default:
      return null
  }
}

export const FormBuilder: FC<GFormProps> = ({formik, generated, variant = 'ROW'}) => {
  return (
    <>
      {generated.map((data, index) => {
        if (data.isShow === null) return true
        if (data.isShow === false) return null

        if (data.type === 'title') {
          return (
            <div key={data.title} className={`mb-6 font-semibold ${data.className ?? ''}`}>
              <div className='text-neutral-900'>{data.title}</div>
              {data.description && (
                <div className='mt-1 font-normal text-fs-10 text-neutral-400'>
                  {data.description}
                </div>
              )}
            </div>
          )
        }

        return (
          <div
            key={(data.title as string) || index}
            className='grid w-full grid-cols-12 mb-6 gap-x-6'
          >
            <div
              className={cn(
                getFormLabelStyle(variant),
                'flex',
                {'items-center': !data.renderForm},
                {'items-start mt-3': data.renderForm},
                data.titleclassname
              )}
            >
              <FormLabel
                description={data.description}
                required={data.required}
                optional={data.optional}
                textClassName='text-neutral-500 font-normal'
                appendTitle={data.appendTitle}
              >
                {data.title}
              </FormLabel>
            </div>

            <div className={cn(getFormListStyle(variant), data.childClassname)}>
              {Array.isArray(data.value) ? (
                <div
                  className={cn(
                    `items-center w-full`,
                    {block: variant === 'FULL'},
                    {'md:flex md:space-x-6': variant === 'ROW'},
                    data.className
                  )}
                >
                  {data.value.map((dt, id) => {
                    if (dt.renderForm) {
                      return (
                        <div
                          key={(dt.value as string) || id}
                          className={cn('mb-4 md:mb-0 flex-1', dt.className, {
                            'md:mb-0': variant === 'ROW',
                          })}
                        >
                          {dt.renderForm}
                        </div>
                      )
                    }

                    return (
                      <CreateForm
                        key={(dt.value as string) || id}
                        data={dt}
                        formik={formik}
                        arrayValue
                        arrayClassName={cn('mb-4 md:mb-0 flex-1', dt.className, {
                          'md:mb-0': variant === 'ROW',
                        })}
                      />
                    )
                  })}
                </div>
              ) : (
                <>
                  {data.renderForm ? data.renderForm : <CreateForm data={data} formik={formik} />}
                </>
              )}
            </div>
          </div>
        )
      })}
    </>
  )
}
