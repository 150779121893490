import {FC} from 'react'
import {Link} from 'react-router-dom'
import {useMasterLayout} from 'src/app/context/MasterContext'
import {toAbsoluteUrl} from 'src/app/utils/asset-helpers.utils'
import {cn} from 'src/app/utils/cn.utils'

const AsideLogo: FC = () => {
  const {minimize} = useMasterLayout()

  return (
    <div className={cn('flex items-center h-[80px] pl-5 py-4')}>
      <Link to='/dashboard' className={cn('relative flex items-center')}>
        {minimize ? (
          <>
            <img
              src={toAbsoluteUrl('/logo_optera_white_small.svg')}
              alt='optera_logo'
              className='w-8 h-8'
            />
          </>
        ) : (
          <img
            src={toAbsoluteUrl('/logo_optera_white.svg')}
            alt='optera_logo'
            className='h-[45px]'
          />
        )}
      </Link>
    </div>
  )
}

export default AsideLogo
