import {FC} from 'react'
import InlineSVG from 'react-inlinesvg'
import {Card} from 'src/app/components/Libs'
import {toAbsoluteUrl} from 'src/app/utils/asset-helpers.utils'

const Error404: FC = () => {
  return (
    <Card.Root
      data-testid='error-404-page-test'
      className='flex items-center justify-center w-full h-[384px]'
    >
      <div className='text-center'>
        <div className='flex justify-center'>
          <InlineSVG src={toAbsoluteUrl('/media/image/image_not_found.svg')} />
        </div>
        <div className='mb-2 font-semibold text-neutral-900'>Halaman tidak ditemukan</div>
        <div className='text-fs-9 text-neutral-500'>
          Anda bisa pilih salah satu menu atau klik logo pada sebelah kiri
        </div>
      </div>
    </Card.Root>
  )
}

export default Error404
