import {ScreenProp} from 'src/app/models'
import {AdminScreen as AdminScreens} from '../page/Admin/Screen'
import {AgenScreen} from '../page/Agen/Screen'

type AdminKeys = keyof typeof AdminScreen
type AgenKeys = keyof typeof AgenScreen

type TKeys = 'USER' | AdminKeys | AgenKeys

const AdminScreen = AdminScreens()

const UserScreens: Record<TKeys, ScreenProp> = {
  USER: {
    PATH: '/user',
    TITLE: 'User',
    TITLE_ALT: 'User',
  },

  ...AdminScreen,
  ...AgenScreen,
}

export default UserScreens
