import {Order} from 'src/app/models'

export enum EAgenStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  INVITED = 'INVITED',
}

export interface IAgen {
  id: string
  agent_id: string
  name: string
  mobile_number: string
  email: string
  employee_number: string
  status: keyof typeof EAgenStatus
}

export interface IAgenDetail {
  id: string
  agent_id: string
  name: string
  mobile_number: string
  status: keyof typeof EAgenStatus
  email: string
  employee_number?: string
}

export interface IAgenRequest {
  employee_number?: string | null
  name: string
  mobile_number: string
  email: string
}

export interface IAgenQuery {
  search?: string
  page?: number
  size?: number
  sort?: string
  order?: Order
  statuses?: string[]
}

interface IAgenBulkValue {
  id?: string
  value: string
  is_valid: boolean
}
export interface IAgenBulkRequest {
  name: IAgenBulkValue
  email: IAgenBulkValue
  employee_number: IAgenBulkValue
  mobile_number: IAgenBulkValue
}
