export function FallbackView() {
  return (
    <div
      data-testid='fallback-view-component'
      className='d-flex flex-column align-items-center justify-content-center w-100 h-100'
    >
      <span className='ms-4'>Loading ...</span>
    </div>
  )
}
