export interface IDefaultPermission {
  group: string
  permissions: IDefaultPermissionData[]
}

interface IDefaultPermissionData {
  name: string
  slug: string
  is_editable: true
}

export enum EPermissions {
  DASHBOARD_VIEW = 'dashboard.view',
  DASHBOARD_EXPORT = 'dashboard.export',

  JOB_VIEW = 'job.view-list',
  JOB_DETAIL = 'job.view-detail',
  JOB_FORM = 'job.add-edit-delete',
  JOB_IMPORT = 'job.import',

  AGEN_VIEW = 'agent.view-list',
  AGEN_DETAIL = 'agent.view-detail',
  AGEN_FORM = 'agent.add-edit',
  AGEN_IMPORT = 'agent.import',

  FREEZE_AGENT_VIEW = 'freeze-agent.view',
  FREEZE_AGENT_DETAIL = 'freeze-agent.view-detail',

  ADMIN_VIEW = 'admin.view-list',
  ADMIN_DETAIL = 'admin.view-detail',
  ADMIN_ADD_L1 = 'admin.add-l1',
  ADMIN_ADD_L2 = 'admin.add-l2',
  ADMIN_ADD_L3 = 'admin.add-l3',
  ADMIN_UPDATE_L1 = 'admin.edit-l1',
  ADMIN_UPDATE_L2 = 'admin.edit-l2',
  ADMIN_UPDATE_L3 = 'admin.edit-l3',
  ADMIN_IMPORT = 'admin.import',

  PERMISSION_VIEW = 'permission.view-list',
  PERMISSION_DETAIL = 'permission.view-detail',
  PERMISSION_FORM = 'permission.add-edit',

  GENERAL_VIEW = 'general-setting.view',
  GENERAL_FORM = 'general-setting.edit',

  COMPANY_VIEW = 'company-setting.view',
  COMPANY_FORM = 'company-setting.edit',

  NOTIFICATION = 'notification',
  CS = 'customer-service',
  PRIVACY = 'data-privacy',
}

export type TPermissions = keyof typeof EPermissions
