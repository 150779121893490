import React from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'
import OnboardingAgentSuccess from '../page/OnboardingAgentSuccess'
import OnboardingPage from '../page/OnboardingPage'
import OnboardingSuccessPage from '../page/OnboardingSuccessPage'
import OnboardingScreens from './Screen'

const OnboardingRoutes: React.FC = () => {
  return (
    <Switch>
      <Route exact path={OnboardingScreens.ONBOARDING.PATH} component={OnboardingPage} />

      <Route
        exact
        path={OnboardingScreens.ONBOARDING_SUCCESS.PATH}
        component={OnboardingSuccessPage}
      />
      <Route
        exact
        path={OnboardingScreens.ONBOARDING_SUCCESS_AGENT.PATH}
        component={OnboardingAgentSuccess}
      />
      <Redirect to={OnboardingScreens.ONBOARDING.PATH} />
    </Switch>
  )
}

export default OnboardingRoutes
