import {FC} from 'react'
import InlineSVG from 'react-inlinesvg'
import {GButton, GModal} from 'src/app/components/Libs'
import {toAbsoluteUrl} from 'src/app/utils/asset-helpers.utils'

const AlertModal: FC<any> = ({modal, setModal, onLeave}) => {
  return (
    <GModal show={modal} onHide={() => setModal(false)} className='w-[400px] h-[280px]'>
      <div className='relative flex flex-col justify-between w-full h-full p-6'>
        <div>
          <div>
            <InlineSVG src={toAbsoluteUrl('/media/image/image_warning.svg')} />
          </div>

          <div className='mt-5'>
            <div className='font-medium text-neutral-900 text-fs-7'>Data akan hilang</div>
            <div className='mt-1 text-neutral-500 text-fs-9'>
              Apakah Anda yakin untuk meninggalkan halaman ini? data yang Anda masukkan akan hilang.{' '}
            </div>
          </div>
        </div>

        <div className='flex items-center justify-between gap-3 mt-8'>
          <GButton
            type='button'
            className='w-full p-2 text-fs-9 border-neutral-300 text-neutral-700 whitespace-nowrap'
            variant='OUTLINED'
            onClick={onLeave}
          >
            Tinggalkan Halaman
          </GButton>
          <GButton type='button' className='w-full p-2 text-fs-9' onClick={() => setModal(false)}>
            Tetap Disini
          </GButton>
        </div>
      </div>
    </GModal>
  )
}

export default AlertModal
