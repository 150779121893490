import axios from 'axios'

export const AUTH = '/api/v1/admins/users'

//old
export const CHANGE_PASSWORD = `/api/v1/users/change-password`
export const REQUEST_RESET_PASSWORD = `/api/v1/users/forgot-password-confirmation`
export const VERIFY_RESET_PASSWORD = `/api/v1/users/forgot-password-detail`

export const loginByEmail = (data?: any) => {
  return axios.post(`${AUTH}/login`, data)
}

export const changePassword = (data?: any, config?: any) => {
  return axios.post(CHANGE_PASSWORD, data, config)
}

export const requestResetPassword = (data?: any) => {
  return axios.post(REQUEST_RESET_PASSWORD, data)
}

export const verifyResetPassword = (id: string) => {
  return axios.get(`${VERIFY_RESET_PASSWORD}/${id}`)
}

export const resetPasswordUser = (data?: any, headers?: any) => {
  return axios.post(`${AUTH}/reset-password`, data, {
    headers,
  })
}

export const verificationUserToken = (data?: any) => {
  return axios.post(`${AUTH}/verification-token`, data)
}

export const forgotPassword = (data?: any, headers?: any) => {
  return axios.post(`${AUTH}/forgot-password`, data, {
    headers,
  })
}

export const logoutUser = () => axios.post(`${AUTH}/logout`)
