import {DetailedHTMLProps, FC, HTMLAttributes} from 'react'
import {cn} from 'src/app/utils/cn.utils'

interface CardProps extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {}

const CardRoot: FC<CardProps> = ({children, className, ...rest}) => {
  return (
    <div
      className={cn(
        'relative bg-white flex flex-col min-w-0 shadow-[0_0_12px_0_rgba(33,37,41,0.1)] rounded-lg',
        className
      )}
      {...rest}
    >
      {children}
    </div>
  )
}

const CardHeader: FC<CardProps> = ({children, className, ...rest}) => {
  return (
    <div className={cn('p-6 flex-nowrap min-h-[72px]', className)} {...rest}>
      {children}
    </div>
  )
}

const CardHeaderTitle: FC<CardProps> = ({children, className}) => {
  return <div className={cn('flex-1 font-semibold text-fs-6', className)}>{children}</div>
}

const CardBody: FC<CardProps> = ({children, className, ...rest}) => {
  return (
    <div className={cn('p-6 min-h-[72px] flex-[1_1_auto]', className)} {...rest}>
      {children}
    </div>
  )
}

const CardFooter: FC<CardProps> = ({children, className, ...rest}) => {
  return (
    <div
      className={cn('px-4 border-t border-[#f1f3f5] flex-nowrap min-h-[24px]', className)}
      {...rest}
    >
      {children}
    </div>
  )
}

export const Card = {
  Root: CardRoot,
  Header: CardHeader,
  HeaderTitle: CardHeaderTitle,
  Body: CardBody,
  Footer: CardFooter,
}
