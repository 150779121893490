import axios from 'axios'
import {BaseResponse, Pagination, Response} from 'src/app/models'
import {
  IJob,
  IJobBulkRequest,
  IJobDetail,
  IJobQuery,
  IJobRequest,
} from 'src/app/models/Job/Job.model'

export const JOB = '/api/v1/admins/tasks'

//react-query key
export const LIST_JOB_KEY = 'list-job'
export const DETAIL_JOB_KEY = 'detail-job'

const multiFormDataHeaders = {
  headers: {
    'Content-Type': 'multipart/form-data',
  },
}

export const getListJob = (params?: IJobQuery) => {
  return axios.get<BaseResponse<Pagination<IJob[]>>>(JOB, {
    params,
  })
}

export const getDetailJob = (id?: string) => {
  return axios.get<BaseResponse<Response<IJobDetail>>>(`${JOB}/${id}`)
}

export const saveJob = (data?: IJobRequest, id?: string) => {
  if (id) return axios.put(`${JOB}/${id}`, data)
  return axios.post(JOB, data)
}

export const updateStatusJob = (job_id?: string, job_status?: 'ACTIVE' | 'INACTIVE') => {
  return axios.patch(`${JOB}/${job_id}/status/${job_status}`)
}

export const updateProgressJob = (
  data?: {reason?: string},
  job_id?: string,
  job_progress?: 'CLOSE' | 'REJECT'
) => {
  return axios.patch(`${JOB}/${job_id}/progress/${job_progress}`, data)
}

export const uploadJob = (data?: any) => {
  return axios.post(`${JOB}/upload`, data, {...multiFormDataHeaders})
}

export const uploadBulkJob = (data?: IJobBulkRequest[], session_id?: string) => {
  return axios.post(`${JOB}/bulk/${session_id}`, data)
}
