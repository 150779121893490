import {useMemo} from 'react'
import {shallowEqual, useSelector} from 'react-redux'
import {Link} from 'react-router-dom'
import {useMasterLayout} from 'src/app/context/MasterContext'
import {IProfile} from 'src/app/models'
import {RootState} from 'src/app/store/RootReducer'
import {cn} from 'src/app/utils/cn.utils'
import AsideMenuItem from './AsideMenuItem'
import AsideMenuItemSub from './AsideMenuItemSub'
import {Menu, useMenus} from './useMenuHook'

export const GeneratedMenu: React.FC<{menu: Menu}> = ({menu}) => {
  const {minimize} = useMasterLayout()

  if (!menu.to) {
    if (!menu.children || menu.children.length === 0) return null
    return (
      <>
        <div className='block p-0'>
          <div className='px-6 pt-8 pb-2'>
            <span
              className={cn('tracking-widest text-neutral-900 uppercase text-fs-10', {
                hidden: minimize,
              })}
            >
              {menu.title}
            </span>
          </div>
        </div>
        {menu.children?.map((child) => {
          return <GeneratedMenu menu={child} key={child.id} />
        })}
      </>
    )
  }
  if (!menu.children)
    return (
      <AsideMenuItem
        to={menu.to}
        icon={menu.icon}
        title={menu.title}
        hasBullet={menu.hasBullet}
        activePath={menu.activePath}
      />
    )

  return (
    <AsideMenuItemSub
      to={menu.to}
      title={menu.title}
      icon={menu.icon}
      activePath={menu.activePath}
      hasBullet={menu.hasBullet}
    >
      {!minimize && (
        <>
          {menu.children.map((child) => {
            return <GeneratedMenu menu={child} key={child.id} />
          })}
        </>
      )}
      {minimize && (
        <>
          {menu.children.map((child) => {
            return (
              <Link key={child.id} to={child.to as string} className='w-full h-full'>
                <div className='py-[18px] pl-[48px] w-full text-white text-fs-9 hover:bg-primary-100/10'>
                  {child.title}
                </div>
              </Link>
            )
          })}
        </>
      )}
    </AsideMenuItemSub>
  )
}

const filterMenus = (
  menus: Menu[] | undefined,
  predicate: (menu: Menu) => boolean
): Menu[] | undefined => {
  const result = menus?.map((menu, index) => ({
    ...menu,
    id: String(index),
    children: filterMenus(menu.children, predicate),
  }))
  return result?.filter((menu) => (!menu.children || menu.children.length > 0) && predicate(menu))
}

const AsideMenuData: React.FC = () => {
  const user: IProfile = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as IProfile

  const menus = useMenus(user)

  const generated = useMemo(() => filterMenus(menus, (menu) => Boolean(!menu.hidden)), [menus])
  return (
    <>
      {generated?.map((child) => {
        return <GeneratedMenu menu={child} key={child.id} />
      })}
    </>
  )
}

export default AsideMenuData
