import {useMutation} from '@tanstack/react-query'
import {useFormik} from 'formik'
import {FC, Fragment, useCallback, useEffect, useMemo, useState} from 'react'
import {DragDropContext, Draggable, Droppable} from 'react-beautiful-dnd'
import {useDispatch} from 'react-redux'
import {useHistory} from 'react-router-dom'
import {Bounce, toast} from 'react-toastify'
import {
  FormCheckbox,
  FormLabel,
  FormSelect,
  FormText,
  FormTextarea,
  GButton,
} from 'src/app/components/Libs'
import GIcon from 'src/app/components/Libs/Icon/GIcon'
import {useOnline} from 'src/app/hooks/online.hook'
import {EFormJobType, IOnboarding} from 'src/app/models'
import {saveOnboarding} from 'src/app/services'
import OnboardingRedux from 'src/app/store/Onboarding/OnboardingRedux'
import {getErrors} from 'src/app/utils/api.utils'
import {formJobTypeOpt} from 'src/app/utils/constant.utils'
import {v4 as uuidv4} from 'uuid'
import OnboardingScreens from '../../routes/Screen'
import AlertModal from '../AlertModal/AlertModal'
import CustomToast from '../CustomToast/CustomToast'
import FormLayout from '../Layout/FormLayout'
import {validation} from './validation'

interface IProps extends IOnboarding {
  token: string
}

const WorkSection: FC<IProps> = ({state, token}) => {
  const dispatch = useDispatch()
  const {isOnline} = useOnline()
  const history = useHistory()

  const [warningModal, setWarningModal] = useState(false)
  const [warningState, setWarningState] = useState<'SKIP' | 'BACK'>()
  const [formValuesChanged, setFormValuesChanged] = useState(false)

  const extractAdminErrors = (detailed: Record<string, string>) => {
    const adminErrors: Record<string, string> = {}
    Object.entries(detailed).forEach(([key, value]) => {
      if (key.startsWith('admin_data.')) {
        const newKey = key.replace('admin_data.', '')
        adminErrors[newKey] = value
      }
    })
    return adminErrors
  }

  const extractUsersErrors = (detailed: Record<string, string>) => {
    const usersErrors: any[] = []
    Object.entries(detailed).forEach(([key, value]) => {
      if (key.startsWith('users.')) {
        const match = key.match(/^users\.(\d+)\.(.+)$/)
        if (match) {
          const [, index, field] = match
          const idx = parseInt(index, 10)
          usersErrors[idx] = usersErrors[idx] || {}
          usersErrors[idx][field] = value
        }
      }
    })
    return usersErrors
  }

  const extractAgentErrors = (e: any) => {
    const agentErrors: Record<string, string> = {}
    const errorsArray = e.response?.data?.response_output?.errors || []
    errorsArray.forEach((error: {field: string; code: string; message: string}) => {
      if (error.code === 'MOBILE_NUMBER_ALREADY_USED') {
        agentErrors['mobile_number'] = error.message
      }
      if (error.code === 'EMAIL_ALREADY_USED') {
        agentErrors['email'] = error.message
      }
    })
    return agentErrors
  }

  const processDetailedErrors = (detailed: Record<string, string>, e: any) => {
    const adminErrors = extractAdminErrors(detailed)
    const usersErrors = extractUsersErrors(detailed)
    const agentErrors = extractAgentErrors(e)

    return {
      admin_data: adminErrors,
      users: usersErrors,
      agent: agentErrors,
    }
  }

  const handleAdminErrors = (adminErrors: Record<string, string>) => {
    if (Object.keys(adminErrors).length > 0) {
      dispatch(OnboardingRedux.actions.setErrors({admin_data: adminErrors}))
      dispatch(OnboardingRedux.actions.setStep(2))
    }
  }

  const handleUserErrors = (usersErrors: any) => {
    if (usersErrors.some((user: any) => user && Object.keys(user).length > 0)) {
      dispatch(OnboardingRedux.actions.setErrors({users: usersErrors}))
      dispatch(OnboardingRedux.actions.setStep(3))
    }
  }

  const handleAgentErrors = (agentErrors: Record<string, string>) => {
    if (Object.keys(agentErrors).length > 0) {
      dispatch(OnboardingRedux.actions.setErrors({agent: agentErrors}))
      dispatch(OnboardingRedux.actions.setStep(4))
    }
  }

  const submitFn = useMutation({
    mutationFn: saveOnboarding,
    onSuccess: () => {
      history.push(OnboardingScreens.ONBOARDING_SUCCESS.PATH)
    },
    onError: (e: any) => {
      if (isOnline) {
        const {detailed} = getErrors(e)

        if (detailed) {
          const data = processDetailedErrors(detailed, e)
          handleAdminErrors(data.admin_data)
          handleUserErrors(data.users)
          handleAgentErrors(data.agent)
        }
      } else {
        toast(<CustomToast />, {
          position: 'bottom-left',
          autoClose: false,
          hideProgressBar: true,
          closeButton: false,
          transition: Bounce,
          className: 'w-[520px] p-0 shadow-none',
          bodyClassName: 'p-0 bg-transparent',
        })
      }
    },
  })

  const endPayload = useMemo(() => {
    const userPayload =
      state?.users && state?.users.length > 0
        ? state.users
            .filter((user) => Object.values(user).every((value) => value !== ''))
            .map((user) => ({
              level: user.level,
              level_name: user.level_name,
              name: user.name,
              email: user.email,
              mobile_number: `+${user.mobile_number}`,
            }))
        : []

    const isAgentEmpty = !state?.agent?.name && !state?.agent?.mobile_number

    const payload = {
      activation_code: token,
      admin_data: {
        email: state?.admin_data?.email,
        mobile_number: `+${state?.admin_data?.mobile_number}`,
        name: state?.admin_data?.name,
        password: state?.admin_data?.password,
      },
      users: userPayload,
      agents: isAgentEmpty
        ? []
        : [
            {
              name: state?.agent?.name,
              email: state?.agent?.email,
              mobile_number: `+${state?.agent?.mobile_number}`,
            },
          ],
      task: state?.task,
    }

    return payload
  }, [state, token])

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: state.task as OnboardingRedux.TTaskState,
    validationSchema: validation,
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: (values) => {
      const userPayload =
        state?.users && state?.users.length > 0
          ? state.users
              .filter((user) => Object.values(user).every((value) => value !== ''))
              .map((user) => ({
                level: user.level,
                level_name: user.level_name,
                name: user.name,
                email: user.email,
                mobile_number: `+${user.mobile_number}`,
              }))
          : []

      const isAgentEmpty =
        !state?.agent?.name && !state?.agent?.mobile_number && !state?.agent?.email

      const payload = {
        activation_code: token,
        admin_data: {
          email: state?.admin_data?.email,
          mobile_number: `+${state?.admin_data?.mobile_number}`,
          name: state?.admin_data?.name,
          password: state?.admin_data?.password,
        },
        users: userPayload,
        agents: isAgentEmpty
          ? []
          : [
              {
                name: state?.agent?.name,
                email: state?.agent?.email,
                mobile_number: `+${state?.agent?.mobile_number}`,
              },
            ],
        task: values,
      }

      submitFn.mutate(payload)
    },
  })

  const addForms = useCallback(() => {
    const newObj = {
      id: uuidv4(),
      description: '',
      type: '',
      name: '',
      data: [{id: uuidv4(), name: ''}],
    }

    formik.setFieldValue('forms', [
      ...(formik.values.forms as Array<OnboardingRedux.TTaskState>),
      newObj,
    ])

    setTimeout(() => {
      window.scrollBy({
        top: 500,
        behavior: 'smooth',
      })
    }, 100)
  }, [formik])

  const deleteForms = useCallback(
    (index: number) => {
      const updatedOptions = formik?.values?.forms?.filter((_: any, id: number) => id !== index)
      formik.setFieldValue(`forms`, updatedOptions)
    },
    [formik]
  )

  const addDataList = useCallback(
    (index: number) => {
      if (!formik.values.forms) return
      const currentOptions = formik.values.forms[index].data ?? []
      formik.setFieldValue(`forms[${index}].data`, [...currentOptions, {id: uuidv4(), name: ''}])
    },
    [formik]
  )

  const deleteDataList = useCallback(
    (formIndex: number, optionIndex: number) => {
      if (!formik.values.forms) return

      const updatedForms = [...formik.values.forms]
      const currentOptions = updatedForms[formIndex].data ?? []

      updatedForms[formIndex] = {
        ...updatedForms[formIndex],
        data: currentOptions.filter((_, id) => id !== optionIndex),
      }

      formik.setFieldValue(`forms`, updatedForms)
    },
    [formik]
  )

  const errorFnForm = (index: any, nameField: string) => {
    const {forms} = formik.errors as any
    if (forms && forms[index] && forms[index][nameField]) {
      return forms[index][nameField]
    }
    return undefined
  }

  const errorFnFormDataList = (formIndex: number, fieldName: string, optionIndex?: number) => {
    const {forms} = formik.errors as any

    if (forms && forms[formIndex]) {
      if (fieldName === 'data' && optionIndex !== undefined) {
        return forms[formIndex].data?.[optionIndex]?.name
      }
      return forms[formIndex][fieldName]
    }
    return undefined
  }

  const handleSkipStep = () => {
    const data = {...endPayload, task: null}
    submitFn.mutate(data)
  }

  const handleBackStep = () => {
    if (formValuesChanged) {
      setWarningModal(true)
      setWarningState('BACK')
    } else {
      dispatch(OnboardingRedux.actions.setStep(4))
    }
  }

  const handleWarningStep = () => {
    if (warningState === 'BACK') {
      dispatch(OnboardingRedux.actions.setStep(4))
    }
  }

  useEffect(() => {
    const newObj = {
      name: '',
      forms: [
        {
          id: uuidv4(),
          description: '',
          type: '',
          name: '',
          data: [{id: uuidv4(), name: ''}],
        },
      ],
    }

    const hasFormValuesChanged = Object.values(formik.values).some((value, index) => {
      return JSON.stringify(value) !== JSON.stringify(Object.values(newObj)[index])
    })

    setFormValuesChanged(hasFormValuesChanged)
  }, [formik.values])

  const reorderData = useCallback(
    (startIndex: number, endIndex: number, index: number) => {
      if (index === null || index === undefined || !formik.values.forms) return
      const updatedForms = [...formik.values.forms]
      const updatedData = [...(updatedForms[index].data ?? [])]
      const [movedRow] = updatedData.splice(startIndex, 1)
      updatedData.splice(endIndex, 0, movedRow)
      updatedForms[index] = {...updatedForms[index], data: updatedData}

      formik.setFieldValue('forms', updatedForms)
    },
    [formik]
  )

  const handleDragEnd = useCallback(
    (result: any, index: any) => {
      const {source, destination} = result
      if (!destination) return
      reorderData(source.index, destination.index, index)
    },
    [reorderData]
  )

  return (
    <FormLayout state={state}>
      <form
        className='w-full bg-white rounded shadow-lg pt-9'
        noValidate
        onSubmit={formik.handleSubmit}
      >
        <div
          onClick={handleSkipStep}
          className='px-10 font-medium underline cursor-pointer text-end text-primary-500'
        >
          Lewati
        </div>

        <div className='w-full px-10 mt-10'>
          <div className='font-semibold text-fs-6 text-neutral-900'>Buat Job</div>
          <div className='mt-[6px] text-neutral-600'>
            Anda bisa menambahkan job lagi pada menu <span className='font-medium'>Job</span>{' '}
            setelah proses onboarding selesai.
          </div>
        </div>

        <div className='w-full py-10 px-9'>
          <div>
            <FormLabel className='mb-2' required>
              Nama job
            </FormLabel>
            <FormText
              {...formik.getFieldProps(`name`)}
              name={`name`}
              placeholder='Masukkan nama job'
              maxLength={256}
              inputClassName='bg-white'
              error={formik.errors.name}
              touched={formik.touched.name}
            />
            <div className='flex mt-2 gap-x-2 text-fs-10 text-neutral-500'>
              <div>
                <GIcon icon='IconInfoOutline' />
              </div>
              Satu job bisa memiliki satu atau lebih tugas
            </div>
          </div>
        </div>

        <div className='w-full px-9'>
          <div className='border-t border-neutral-300'></div>
        </div>

        <div className='py-10 font-semibold text-neutral-900 px-9'>
          Buat Tugas Pertama untuk Tim Anda
        </div>

        <div className='px-9'>
          {formik.values.forms?.map((data, index) => (
            <Fragment key={data.id}>
              <div className='flex items-center mb-6'>
                <div className='flex-grow border-t border-neutral-300'></div>
                <span className='mx-4 text-neutral-900'>Tugas ke {index + 1}</span>
                <div className='flex-grow border-t border-neutral-300'></div>
              </div>

              <div>
                <FormLabel className='mb-2' required>
                  Nama tugas
                </FormLabel>
                <FormText
                  {...formik.getFieldProps(`forms[${index}].name`)}
                  name={`forms[${index}].name`}
                  placeholder='Masukkan nama tugas'
                  maxLength={256}
                  inputClassName='bg-white'
                  error={errorFnForm(index, 'name')}
                  touched={true}
                />
                <div className='flex mt-2 gap-x-2 text-fs-10 text-neutral-500'>
                  <div>
                    <GIcon icon='IconInfoOutline' />
                  </div>
                  Nama tugas ini yang akan dikerjakan oleh agen
                </div>
              </div>

              <div className='mt-6'>
                <FormLabel className='mb-2' required>
                  Bentuk laporan tugas
                </FormLabel>
                <FormSelect
                  {...formik.getFieldProps(`forms[${index}].type`)}
                  name={`forms[${index}].type`}
                  placeholder='Pilih bentuk laporan tugas'
                  options={formJobTypeOpt}
                  changedValue={(v: any) => {
                    formik.setFieldValue(`forms[${index}].type`, v.value)
                  }}
                  selectedValue={formik.values[`forms`] && formik.values[`forms`][index][`type`]}
                  error={errorFnForm(index, 'type')}
                  touched={true}
                />
                <div className='flex mt-2 gap-x-2 text-fs-10 text-neutral-500'>
                  <div>
                    <GIcon icon='IconInfoOutline' />
                  </div>
                  Bentuk jawaban yang akan diisi oleh agen
                </div>
              </div>

              {formik.values.forms &&
                formik.values.forms[index][`type`] &&
                (formik.values.forms[index][`type`] === EFormJobType.MULTIPLE_CHOICE ||
                  formik.values.forms?.[index]?.type === EFormJobType.DROPDOWN) && (
                  <div className='mt-6 ml-10'>
                    <DragDropContext onDragEnd={(res) => handleDragEnd(res, index)}>
                      <Droppable droppableId='forms' direction='vertical'>
                        {(provided) => (
                          <div {...provided.droppableProps} ref={provided.innerRef}>
                            {formik.values.forms?.[index]?.type === EFormJobType.MULTIPLE_CHOICE &&
                              formik.values.forms[index]?.data?.map((opt, optId) => (
                                <Draggable key={opt.id} draggableId={String(opt.id)} index={optId}>
                                  {(provided) => (
                                    <div
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      key={data?.id}
                                    >
                                      <div className='flex items-start w-full mt-4 gap-x-4'>
                                        {(formik.values.forms?.[index]?.data?.length as number) >
                                          1 && (
                                          <div
                                            {...provided.dragHandleProps}
                                            className='mt-3 cursor-pointer'
                                          >
                                            <GIcon icon='IconDrag' />
                                          </div>
                                        )}
                                        <div>
                                          <FormCheckbox
                                            variant='classic'
                                            checked={false}
                                            disabled
                                          />
                                        </div>
                                        <div className='w-full'>
                                          <FormText
                                            {...formik.getFieldProps(
                                              `forms[${index}].data[${optId}].name`
                                            )}
                                            name={`forms[${index}].data[${optId}].name`}
                                            placeholder={`Opsi ${optId + 1}`}
                                            maxLength={256}
                                            inputClassName='bg-white w-full'
                                            error={errorFnFormDataList(index, 'data', optId)}
                                            touched={true}
                                          />
                                        </div>
                                        {(formik.values.forms?.[index]?.data?.length as number) >
                                          1 && (
                                          <div
                                            onClick={() => deleteDataList(index, optId)}
                                            className='flex items-center gap-2 cursor-pointer text-danger h-9'
                                          >
                                            <GIcon icon='IconDelete' />
                                          </div>
                                        )}
                                      </div>
                                    </div>
                                  )}
                                </Draggable>
                              ))}

                            {formik.values.forms?.[index]?.type === EFormJobType.DROPDOWN &&
                              formik.values.forms[index]?.data?.map((opt, optId) => (
                                <Draggable key={opt.id} draggableId={String(opt.id)} index={optId}>
                                  {(provided) => (
                                    <div
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      key={data?.id}
                                    >
                                      <div className='flex items-start w-full mt-4 gap-x-4'>
                                        {(formik.values.forms?.[index]?.data?.length as number) >
                                          1 && (
                                          <div
                                            {...provided.dragHandleProps}
                                            className='mt-3 cursor-pointer'
                                          >
                                            <GIcon icon='IconDrag' />
                                          </div>
                                        )}
                                        <div className='mt-3 font-medium text-neutral-900'>
                                          {optId + 1}.
                                        </div>
                                        <div className='w-full'>
                                          <FormText
                                            {...formik.getFieldProps(
                                              `forms[${index}].data[${optId}].name`
                                            )}
                                            name={`forms[${index}].data[${optId}].name`}
                                            placeholder={`Opsi ${optId + 1}`}
                                            maxLength={256}
                                            inputClassName='bg-white w-full'
                                            error={errorFnFormDataList(index, 'data', optId)}
                                            touched={true}
                                          />
                                        </div>
                                        {(formik.values.forms?.[index]?.data?.length as number) >
                                          1 && (
                                          <div
                                            onClick={() => deleteDataList(index, optId)}
                                            className='flex items-center gap-2 cursor-pointer text-danger h-9'
                                          >
                                            <GIcon icon='IconDelete' />
                                          </div>
                                        )}
                                      </div>
                                    </div>
                                  )}
                                </Draggable>
                              ))}

                            {provided.placeholder}

                            {(formik.values.forms?.[index]?.data?.length as number) < 3 && (
                              <div className='items-center hidden mt-6 last:flex gap-7'>
                                <div className='flex items-center flex-1 h-9'>
                                  <div className='w-full flex items-center h-[1px] bg-neutral-300'></div>
                                </div>

                                <div
                                  onClick={() => addDataList(index)}
                                  className='flex items-center gap-2 font-medium cursor-pointer text-primary-500 h-9'
                                >
                                  <GIcon icon='IconAdd' />
                                  <span className='text-fs-9'>Tambah Pilihan</span>
                                </div>
                              </div>
                            )}
                          </div>
                        )}
                      </Droppable>
                    </DragDropContext>
                  </div>
                )}

              <div className='mt-6'>
                <FormLabel className='mb-2' required>
                  Deskripsi tugas
                </FormLabel>
                <FormTextarea
                  {...formik.getFieldProps(`forms[${index}].description`)}
                  name={`forms[${index}].description`}
                  placeholder='Masukkan deskripsi tugas...'
                  maxLength={256}
                  inputClassName='bg-white'
                  error={errorFnForm(index, 'description')}
                  touched={true}
                />
              </div>

              <div className='flex items-center py-8 gap-7'>
                <div className='flex items-center flex-1 h-9'>
                  <div className='w-full flex items-center h-[1px] bg-neutral-300'></div>
                </div>

                {formik.values.forms && formik.values.forms.length > 1 && (
                  <div
                    onClick={() => deleteForms(index)}
                    className='flex items-center gap-2 font-medium cursor-pointer text-danger h-9'
                  >
                    <GIcon icon='IconDelete' />
                    <span className='text-fs-9'>Hapus</span>
                  </div>
                )}

                {formik.values.forms &&
                  formik.values.forms.length < 3 &&
                  formik.values.forms.length - 1 === index && (
                    <div
                      onClick={addForms}
                      className='flex items-center gap-2 font-medium cursor-pointer text-primary-500 h-9'
                    >
                      <GIcon icon='IconAdd' />
                      <span className='text-fs-9'>Tambah Tugas</span>
                    </div>
                  )}
              </div>
            </Fragment>
          ))}
        </div>

        <div className='w-full border-t border-neutral-200'>
          <div className='flex items-center justify-end gap-4 px-10 py-5'>
            <GButton onClick={handleBackStep} size={'large'} type='button' variant='OUTLINED'>
              Back
            </GButton>
            <GButton size={'large'} type='submit'>
              Kirim
            </GButton>
          </div>
        </div>
      </form>

      <AlertModal modal={warningModal} setModal={setWarningModal} onLeave={handleWarningStep} />
    </FormLayout>
  )
}

export default WorkSection
