import React, {useRef, useState} from 'react'
import {useLocation} from 'react-router'
import {useMasterLayout} from 'src/app/context/MasterContext'
import ClientPortal from 'src/app/hooks/portal.hook'
import useWindowDimensions from 'src/app/hooks/window-dimension.hook'
import {cn} from 'src/app/utils/cn.utils'
import {checkIsActive} from 'src/app/utils/router-helpers.utils'
import {KTSVG} from '../../Libs/Icon/KTSVG'
import BulletItem from './BulletItem'

type Props = {
  children?: any
  to: string
  title: string
  icon?: string
  hasBullet?: boolean
  activePath?: string
}

const AsideMenuItemSub: React.FC<Props> = ({children, to, title, icon, hasBullet}) => {
  const {pathname} = useLocation()
  const isActive = checkIsActive(pathname, to)
  const {minimize} = useMasterLayout()
  const splitPathname = '/' + pathname.split('/')[1]
  const {width} = useWindowDimensions()

  const positionYRef = useRef<number | null>(null) // Use ref to store the position

  const [open, setOpen] = useState<string>(splitPathname)
  const [hovered, setHovered] = useState(false)
  const [positionY, setPositionY] = useState(0)

  const handleOpen = (value: string) => {
    setOpen(open === value ? '' : value)
  }

  const handleMouseEnter = (e: React.MouseEvent) => {
    if (positionYRef.current === null) {
      const yPosition = e.clientY
      setPositionY(yPosition - yPosition * 0.1)
      positionYRef.current = yPosition
    }
    if (width >= 992) setHovered(true)
  }

  return (
    <div className='relative'>
      <div
        className={cn('p-0 block overflow-hidden text-fs-9 relative', {
          'overflow-visible': to,
        })}
        onClick={() => handleOpen(to)}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={() => setHovered(false)}
      >
        <span
          className={cn(
            'bg-none p-2 m-2 cursor-pointer flex items-center transition duration-100 h-10',
            'hover:text-white/70 text-white',
            {'text-white': !open}
          )}
        >
          {hasBullet && <BulletItem isActive={isActive} />}

          {icon && (
            <span className='flex items-center w-8 mr-2 shrink-0'>
              <KTSVG path={icon} className='w-6 h-6' />
            </span>
          )}

          <span
            className={cn('truncate flex items-center flex-1 transition-all duration-300', {
              hidden: minimize,
            })}
          >
            {title}
          </span>
          <span
            className={cn(
              'flex items-stretch relative overflow-hidden ml-2 min-w-[0.8rem] h-[0.8rem] transition ease-in',
              'after:block after:content-[""] after:w-full after:bg-[url("/public/media/icons/IconChevronRight.svg")] after:bg-center after:bg-no-repeat',
              {'rotate-90': open === to},
              {'-ml-2': minimize}
            )}
          ></span>
        </span>

        {/* expanded  */}
        {!minimize && (
          <div
            onClick={(e) => e.stopPropagation()}
            className={cn(
              `rounded-lg transition-all overflow-hidden duration-300 ease-in ${
                open === to && 'max-h-[15rem]'
              }`,
              {'max-h-0 ': open !== to},
              {'ml-0 mr-0 pl-0': minimize}
            )}
          >
            {children}
          </div>
        )}

        {minimize && (
          <ClientPortal>
            <div
              className='absolute left-[72px] flex'
              style={{
                top: positionY,
              }}
            >
              <div className='w-[8px]'></div>
              <div
                onClick={(e) => e.stopPropagation()}
                className={cn(
                  'overflow-hidden bg-gradient-to-tl from-primary-600 to-primary-600 w-[252px] rounded-lg',
                  !hovered && 'hidden'
                )}
                onMouseLeave={() => setHovered(false)}
              >
                {children}
              </div>
            </div>
          </ClientPortal>
        )}
      </div>
    </div>
  )
}

export default AsideMenuItemSub
