import axios from 'axios'
import {
  BaseResponse,
  IAdmin,
  IAdminBulkRequest,
  IAdminDetail,
  IAdminQuery,
  IAdminRequest,
  Pagination,
  Response,
} from 'src/app/models'

export const USER = '/api/v1/admins/users'
export const RESEND_EMAIL = '/api/v1/admins/resend-invitation'

//react-query key
export const LIST_USER_KEY = 'list-users'
export const DETAIL_USER_KEY = 'detail-users'

const multiFormDataHeaders = {
  headers: {
    'Content-Type': 'multipart/form-data',
  },
}

export const getListUser = (params?: IAdminQuery) => {
  return axios.get<BaseResponse<Pagination<IAdmin[]>>>(USER, {
    params,
  })
}

export const getDetailUser = (id?: string) => {
  return axios.get<BaseResponse<Response<IAdminDetail>>>(`${USER}/${id}`)
}

export const saveUser = (data?: IAdminRequest, id?: string) => {
  if (id) return axios.put(`${USER}/${id}`, data)
  return axios.post(USER, data)
}

export const deleteUser = (id?: string) => {
  return axios.delete(`${USER}/${id}`)
}

export const updateStatusUser = (user_id?: string, user_status?: 'ACTIVE' | 'INACTIVE') => {
  return axios.patch(`${USER}/${user_id}/status/${user_status}`)
}

export const uploadUser = (data?: any) => {
  return axios.post(`${USER}/upload`, data, {...multiFormDataHeaders})
}

export const uploadBulkUser = (data?: IAdminBulkRequest[], session_id?: string) => {
  return axios.post(`${USER}/bulk/${session_id}`, data)
}

export const resendEmailInvitation = (data?: any) => {
  return axios.post(RESEND_EMAIL, data)
}
