import {FC} from 'react'
import {IOnboarding} from 'src/app/models'
import {cn} from 'src/app/utils/cn.utils'

interface INumberSteps {
  active: boolean
  number: number
  title: string
}

const NumberSteps: FC<INumberSteps> = ({active, number, title}) => {
  return (
    <div className='flex items-center'>
      <div
        className={cn('flex items-center justify-center w-8 h-8 text-white rounded-full', {
          'border border-primary-500': active,
          'bg-neutral-50': !active,
        })}
      >
        <div
          className={cn('flex items-center justify-center w-6 h-6 font-semibold rounded-full', {
            'bg-primary-500': active,
            'text-neutral-600 bg-neutral-300': !active,
          })}
        >
          {number}
        </div>
      </div>
      <div
        className={cn('ml-4 font-medium', {
          'text-primary-500': active,
          'text-neutral-600': !active,
        })}
      >
        {title}
      </div>
    </div>
  )
}

const PoleSteps: FC<{active: boolean}> = ({active}) => {
  return (
    <div className='flex justify-center w-8'>
      <div className={cn('w-[2px] h-10', active ? 'bg-primary-500' : 'bg-neutral-300')}></div>
    </div>
  )
}

const StartingSteps: FC<IOnboarding> = ({state}) => {
  return (
    <div className='pt-10 pb-8 pr-8'>
      <div className='font-semibold text-neutral-900 text-fs-6'>Langkah memulai</div>

      <div className='mt-10'>
        <NumberSteps active={(state?.step as number) >= 3} number={1} title='Undang Admin' />

        <PoleSteps active={(state?.step as number) >= 3} />

        <NumberSteps active={(state?.step as number) >= 4} number={2} title='Tambahkan Agen' />

        <PoleSteps active={(state?.step as number) >= 4} />

        <NumberSteps active={(state?.step as number) >= 5} number={3} title='Buat Job' />
      </div>
    </div>
  )
}

export default StartingSteps
