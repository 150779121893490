import React from 'react'
import SVG from 'react-inlinesvg'
import {toAbsoluteUrl} from 'src/app/utils/asset-helpers.utils'

export type KTSVGProps = {
  className?: string
  path: string
  svgClassName?: string
}

const KTSVG: React.FC<KTSVGProps> = ({className = '', path, svgClassName = 'mh-50px'}) => {
  return (
    <span className={`leading-none ${className}`}>
      <SVG src={toAbsoluteUrl(path)} className={svgClassName} />
    </span>
  )
}

export {KTSVG}
