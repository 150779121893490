import {useCallback} from 'react'
import {shallowEqual, useSelector} from 'react-redux'
import {EPermissions} from '../models/User/Permission.model'
import {RootState} from '../store/RootReducer'

export function usePermissions() {
  const permissions = useSelector<RootState>(
    ({auth}) => auth.permissions,
    shallowEqual
  ) as EPermissions[]

  const hasAccess = useCallback(
    (payload: EPermissions | EPermissions[]) => {
      if (Array.isArray(payload)) {
        // Check if the user has at least one permission from the array
        return payload.some((perm) => permissions.includes(perm))
      }
      // Single permission check
      return permissions.includes(payload)
    },
    [permissions]
  )

  return {hasAccess}
}
